import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import styles from "./GenerateCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { residentialActions } from "../../store/residential";
import Login from "../Login/Login";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import Cookies from "js-cookie";
import Modal from "../UI/Modal/Modal";
import { useLocation } from "react-router";

const GenerateCard = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);
  const dispatch = useDispatch();
  const proxyType = useSelector((state) => state.residential.proxyType);
  const proxyCountry = useSelector((state) => state.residential.proxyCountry);

  const US_State = useSelector((state) => state.residential.US_State);

  const proxyQuantity = useSelector((state) => state.residential.proxyQuantity);
  const saucedType = useSelector((state) => state.residential.saucedType);

  const location = useLocation();

  const [message, setMessage] = useState();
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);

  const [wherAmI, setWherAmI] = useState("residential");
  useEffect(() => {
    if (location.pathname === "/") setWherAmI("residential");
    else if (location.pathname === "/DC") setWherAmI("isp");
  }, [location]);

  const handleBuy = () => {
    window.location.href = process.env.REACT_APP_WEBSITE;
  };
  const handleGenerateProxies = () => {
    if (user) {
      let user_discord_id = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[0]
        .split(":")[1]
        .slice(1, -1);
      if (
        proxyType &&
        proxyCountry != "Select Country" &&
        proxyCountry &&
        proxyQuantity &&
        saucedType &&
        !isNaN(proxyQuantity)
      ) {
        // if (proxyCountry === "US" && !US_State && saucedType === "premium") {
        //   setShow(true);
        //   setMessage("please select all data");
        // } else {
        axiosInstance
          .post("secretplan/generateproxies", {
            country: proxyCountry,
            amount: proxyQuantity,
            proxyType: proxyType,
            saucedType: saucedType,
            user_discord_id: user_discord_id,
            US_State: US_State,
          })
          .then((response) => {
            if (response.data?.proxies) {
              // here we will change the resedentials proxies state using dispatch
              if (saucedType === "lite") {
                window.localStorage.setItem(
                  "resedintial-lite-proxies",
                  response.data.proxies
                );
                dispatch(
                  residentialActions.changeResedintialProxies(
                    response.data.proxies
                  )
                );
              } else if (saucedType === "premium") {
                window.localStorage.setItem(
                  "resedintial-premium-proxies",
                  response.data.proxies
                );
                dispatch(
                  residentialActions.changeResedintialProxies(
                    response.data.proxies
                  )
                );
              } else if (saucedType === "saucedSheeesh") {
                window.localStorage.setItem(
                  "resedintial-saucedSheeesh-proxies",
                  response.data.proxies
                );
                dispatch(
                  residentialActions.changeResedintialProxies(
                    response.data.proxies
                  )
                );
              }

              dispatch(residentialActions.changeProxyQuantity(undefined));
              dispatch(residentialActions.changeProxyCountry(""));
              dispatch(residentialActions.changeProxyType("sticky"));
            } else {
              setShow(true);
              setMessage("purchase proxies first!");
            }
          });
        // }
      } else {
        setShow(true);
        setMessage("please select all data");
      }
    } else {
      showLoginFn();
    }
  };

  const closeModal = () => {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  };
  function closeModalLogin() {
    setShowLogin(false);
    setSideDrawer(false);
    setBackdrop(false);
  }

  function closeBackdrop() {
    setBackdrop(false);
    setSideDrawer(false);
  }
  function showLoginFn() {
    setShowLogin(true);
    setSideDrawer(false);
  }
  return (
    <>
      {!user &&
        ReactDOM.createPortal(
          <Modal
            show={showLogin}
            closeModal={closeModalLogin}
            messageModal={true}
          >
            <Login closeModal={closeModalLogin} />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {!user &&
        ReactDOM.createPortal(
          <SideDrawer
            show={sideDrawer}
            closeClicked={closeBackdrop}
            showLogin={showLoginFn}
          />,
          document.getElementById("sideDrawer-root")
        )}
      <div className={styles.generateCard}>
        {ReactDOM.createPortal(
          <Modal closeModal={closeModal} show={show} messageModal={message}>
            <div className="message">
              <span>{message}</span>
              <button onClick={closeModal}>Ok</button>
            </div>{" "}
          </Modal>,
          document.getElementById("modal-root")
        )}
        <div className={styles.btnsContainer}>
          {wherAmI === "residential" && (
            <button className={styles.generate} onClick={handleGenerateProxies}>
              Generate Proxies
            </button>
          )}
          <button className={styles.buy} onClick={handleBuy}>
            Buy
          </button>
        </div>
      </div>
    </>
  );
};
export default GenerateCard;
