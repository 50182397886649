import { configureStore } from "@reduxjs/toolkit";
import residentialReducer from "./residential";
import ispReducer from "./isp";
import dcIspReducer from "./dataCenter";
import offsetReducer from "./offsets";

import countriesReducer from "./countriesSlice";

const store = configureStore({
  reducer: {
    residential: residentialReducer,
    isp: ispReducer,
    offset: offsetReducer,
    dc: dcIspReducer,
    countries: countriesReducer,
  },
});

export default store;
