import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  flag: false,
  isAuth: false,
  user: null,
};
const offsetsSlice = createSlice({
  name: "offsets",
  initialState,
  reducers: {
    setFlag(state, action) {
      state.flag = !state.flag;
    },
    login(state, action) {
      state.isAuth = true;
      state.user = action.payload;
    },
    logout(state, action) {
      state.isAuth = false;
      state.user = action.payload;
    },
  },
});
export default offsetsSlice.reducer;
export const offsetsActions = offsetsSlice.actions;
