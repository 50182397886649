import NavigationItems from "../NavigationItems/NavigationItems";
import styles from "./SideDrawer.module.css";
import logo from "../../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";

const SideDrawer = (props) => {
  return (
    <div
      className={`${styles.sideDrawer} ${
        props.show ? styles.open : styles.close
      }`}
    >
      <NavLink to="/">
        <div className={styles.logo} onClick={() => props.closeClicked()}>
          <img src={logo} alt="logo" />
        </div>
      </NavLink>
      <div className={styles.navContainer}>
        <NavigationItems
          showSideDrawer={props.show}
          closed={props.closeClicked}
          showLogin={props.showLogin}
        />
      </div>
    </div>
  );
};
export default SideDrawer;
