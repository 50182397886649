import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sagaPlanCapacity: "2GB",
  sagaCapacityPrice: 16,
  edgePlanCapacity: "2GB",
  edgeCapacityPrice: 32,
  thirdPlanCapacity: "2GB",
  thirdCapacityPrice: 44,
  proxyType: "sticky",
  proxyCountry: "",
  US_State: "",
  proxyQuantity: 0,
  resedintialProxies: [],
  saucedType: "lite",
};
const residentialPricesFirst = {
  "3GB": 56,
  "6GB": 109,
  "9GB": 149,
  "12GB": 199,
};
const residentialPricesSecond = {
  "3GB": 56,
  "6GB": 109,
  "9GB": 149,
  "15GB": 199,
};
const residentialPricesThird = {
  "2GB": 44,
  "3GB": 66,
  "5GB": 110,
  "10GB": 220,
};
const residentialSlice = createSlice({
  name: "residentialPlan",
  initialState,
  reducers: {
    sagaPrices(state, action) {
      state.sagaCapacityPrice = parseInt(action.payload.replace("GB", "")) * 8;
      state.sagaPlanCapacity = action.payload;
    },
    edgePrices(state, action) {
      state.edgeCapacityPrice = parseInt(action.payload.replace("GB", "")) * 16;
      state.edgePlanCapacity = action.payload;
    },
    thirdPrices(state, action) {
      state.thirdCapacityPrice = residentialPricesThird[action.payload];
      state.thirdPlanCapacity = action.payload;
    },
    changeProxyType(state, action) {
      state.proxyType = action.payload;
    },
    changeProxyCountry(state, action) {
      state.proxyCountry = action.payload;
    },
    changeProxyQuantity(state, action) {
      state.proxyQuantity = action.payload;
    },
    changeResedintialProxies(state, action) {
      state.resedintialProxies = action.payload;
    },
    removeResedintialProxies(state, action) {
      state.resedintialProxies = action.payload;
    },
    changeSaucedType(state, action) {
      state.saucedType = action.payload;
    },
    changeUS_State(state, action) {
      state.US_State = action.payload;
    },
  },
});
export default residentialSlice.reducer;
export const residentialActions = residentialSlice.actions;
