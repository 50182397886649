import styles from "./Login.module.css";
import discordButton from "../../assets/images/discord.svg";
import twitter from "../../assets/images/twitter.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { offsetsActions } from "../../store/offsets";

const Login = (props) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogin = async () => {
    if (Cookies.get("subUser")) {
      const response = await axiosInstance.get("auth/login");
      if (response.data.user) {
        localStorage.setItem("auth-user-sauce", response.data.user);
        dispatch(offsetsActions.login(response.data.user));
        history.push("/");
      } else {
        window.location.href = `${process.env.REACT_APP_API_URL}auth/discord/`;
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/discord/`;
    }
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.content}>
        <div className={styles.title}>
          <p className={styles.login}>Log In</p>
          <p className={styles.getStarted}>to get started</p>
        </div>
        <button className={styles.discordBtn} onClick={handleLogin}>
          <img src={discordButton} alt="discord" className={styles.discord} />
          Sign in with Discord
        </button>
        {/* <button className={styles.twitterBtn}>
          <img src={twitter} alt="twitter" className={styles.discord} />
          Sign in with Twitter
        </button> */}
      </div>
    </div>
  );
};
export default Login;
