import styles from "./ISPDoughnutChart.module.css";
import { Doughnut } from "react-chartjs-2";
import { useEffect, useState } from "react";
import moment from "moment";
import { dcIspActions } from "../../../store/dataCenter";
import Cookies from "js-cookie";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

const ISPDoughnutChart = (props) => {
  const dispatch = useDispatch();
  const [traffic, setTraffic] = useState(undefined);
  const [remainingQuota, setRemainingQuota] = useState(undefined);
  const user = useSelector((state) => state.offset.user);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  useEffect(() => {
    if (props.activeOne && user) {
      let user_discord_id = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[0]
        .split(":")[1]
        .slice(1, -1);
      let subscriptionId = props.activeOne.subscriptionId;
      retrievePlan(user_discord_id, subscriptionId);

      let expirationDate = moment(props.activeOne?.expiry);
      let todayDate = moment(new Date());
      let remainingQuota = expirationDate.diff(todayDate, "days") + 1; //which mean the days left

      let traffic = 30 - remainingQuota; //which mean the days that I used
      setTraffic(traffic);
      setRemainingQuota(remainingQuota);
    } else {
      setTraffic(undefined);
      setRemainingQuota(undefined);
    }
  }, [props]);

  function retrievePlan(user_discord_id, subscriptionId) {
    if (subscriptionId) {
      const body = {
        user_discord_id,
        subscriptionId,
      };
      axiosInstance
        .post("retrieve-plan", body)
        .then(({ data }) => {
          if (data.message == "expanded") {
            // let expanded = data.message;
            dispatch(dcIspActions.changeDeclinedMsgISP(true));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className={styles.doughnutChart}>
      {!user && (
        <div className={styles.notAvailable}>
          <h4>Please Login!</h4>
        </div>
      )}
      <Doughnut
        width={130}
        height={130}
        data={{
          labels: ["", "Remaining"],
          datasets: [
            {
              backgroundColor: ["rgba(224, 224, 255, 0.03)", "#16B8B8"],
              data: [traffic, remainingQuota], //traffic is the consumption
              borderWidth: [0, 0],
              yAxisID: "yAxis",
            },
          ],
        }}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          cutout: 55,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
      <div className={styles.title}>
        <p className={styles.number}>{remainingQuota}</p>
        <p className={styles.gb}>
          {remainingQuota === 1 ? "Day" : "Days"} left
        </p>
      </div>
    </div>
  );
};
export default ISPDoughnutChart;
