import styles from "./ResiProxiesCard.module.css";
import arrow from "../../../assets/images/arrow.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { countriesActions } from "../../../store/countriesSlice";
import { residentialActions } from "../../../store/residential";

import { usStatesList } from "../../../utils/usStatesList";

import axios from "axios";
const ResiProxiesCard = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const [message, setMessage] = useState("");
  // const [proxyState, setProxyState] = useState("Select State");
  const countries = useSelector((state) => state.countries.countries);

  const proxyType = useSelector((state) => state.residential.proxyType);
  const proxyCountry = useSelector((state) => state.residential.proxyCountry);
  const proxyQuantity = useSelector((state) => state.residential.proxyQuantity);

  const US_State = useSelector((state) => state.residential.US_State);

  const saucedType = useSelector((state) => state.residential.saucedType);

  const resedintialProxies = useSelector(
    (state) => state.residential.resedintialProxies
  );

  useEffect(() => {
    if (saucedType === "lite") {
      if (localStorage.getItem("resedintial-lite-proxies")) {
        dispatch(
          residentialActions.changeResedintialProxies(
            localStorage.getItem("resedintial-lite-proxies").split(",")
          )
        );
      } else {
        dispatch(residentialActions.changeResedintialProxies([]));
      }
    } else if (saucedType === "premium") {
      if (localStorage.getItem("resedintial-premium-proxies")) {
        dispatch(
          residentialActions.changeResedintialProxies(
            localStorage.getItem("resedintial-premium-proxies").split(",")
          )
        );
      } else {
        dispatch(residentialActions.changeResedintialProxies([]));
      }
    }else if (saucedType === "saucedSheeesh") {
      if (localStorage.getItem("resedintial-saucedSheeesh-proxies")) {
        dispatch(
          residentialActions.changeResedintialProxies(
            localStorage.getItem("resedintial-saucedSheeesh-proxies").split(",")
          )
        );
      } else {
        dispatch(residentialActions.changeResedintialProxies([]));
      }
    }
  }, [dispatch, residentialActions, saucedType]);

  useEffect(() => {
    if (user) {
      axiosInstance
        .get("countries/getcountries?plan=lemonlabs")
        .then((response) => {
          dispatch(countriesActions.getCountries([]));
          dispatch(countriesActions.getCountries(response.data.countries));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const handleProxyTypeChange = (event) => {
    dispatch(residentialActions.changeProxyType(event.target.value));
  };
  const handleCountryChange = (event) => {
    dispatch(residentialActions.changeProxyCountry(event.target.value));
  };

  const handleUS_StateChange = (event) => {
    dispatch(residentialActions.changeUS_State(event.target.value));
  };

  const handleProxyQuantityChange = (event) => {
    dispatch(residentialActions.changeProxyQuantity(event.target.value));
  };

  const handleSaucedTypeChange = (event) => {
    dispatch(residentialActions.changeSaucedType(event.target.value));
  };

  const countryList = countries?.map((country) => (
    <option value={country} key={country}>
      {country}
    </option>
  ));

  const statesList = usStatesList?.map((US_State) => (
    <option value={US_State} key={US_State}>
      {US_State}
    </option>
  ));

  useEffect(() => {
    if (copied) {
      var hideAlert = setTimeout(() => setCopied(false), 1000);
    }

    return () => clearTimeout(hideAlert);
  }, [copied]);
  return (
    <div className={styles.proxiesContainer}>
      {copied && <div className={styles.copiedAlert}>{message}</div>}
      <div className={styles.header}>
        <p className={styles.title}>Proxy List</p>
        <div className={styles.icons}>
          <svg
            onClick={() => {
              if (resedintialProxies?.length > 0) {
                const element = document.createElement("a");
                const file = new Blob([resedintialProxies.join("\n")], {
                  type: "text/plain",
                });
                element.href = URL.createObjectURL(file);
                element.download = "proxies.txt";
                document.body.appendChild(element);
                element.click();
                setCopied(true);
                setMessage("Downloaded!");
              } else {
                setCopied(true);
                setMessage("you have no proxies!");
              }
            }}
            id="save_alt-24px"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path id="Path" d="M0,0H20V20H0Z" fill="none" />
            <path
              id="Shape"
              d="M1.667,15A1.672,1.672,0,0,1,0,13.333v-5a.833.833,0,1,1,1.667,0V12.5a.836.836,0,0,0,.833.833h10a.836.836,0,0,0,.833-.833V8.333a.833.833,0,1,1,1.667,0v5A1.672,1.672,0,0,1,13.333,15Zm5.242-4.342L3.917,7.667A.831.831,0,0,1,5.091,6.492L6.666,8.059V.833a.833.833,0,1,1,1.667,0V8.059L9.9,6.492a.831.831,0,0,1,1.175,1.175L8.083,10.659a.829.829,0,0,1-1.174,0Z"
              transform="translate(2.5 2.5)"
              fill="#ff2c53"
            />
          </svg>

          <svg
            onClick={() => {
              let result = "";
              resedintialProxies?.map((proxy) => {
                result += proxy + "\n";
                return result;
              });
              navigator.clipboard.writeText(result);
              setCopied(true);
              console.log("copied.......");
              if (resedintialProxies?.length > 0) {
                setMessage("Copied!");
              } else {
                setMessage("you have no proxies!");
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="14.763"
            height="17.725"
            viewBox="0 0 14.763 17.725"
          >
            <g id="Group_2" data-name="Group 2" transform="translate(0.5 0.5)">
              <path
                id="Path"
                d="M8.275,16.725a1.984,1.984,0,0,0,2.207-2.22v-1.2h1.074a1.989,1.989,0,0,0,2.207-2.227V6.042A2.624,2.624,0,0,0,13.034,4L9.818.729A2.513,2.513,0,0,0,7.891,0H5.482a1.982,1.982,0,0,0-2.2,2.22v1.2H2.207A1.984,1.984,0,0,0,0,5.645v8.861a1.981,1.981,0,0,0,2.207,2.22Z"
                fill="#ff2c53"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M6.621,10.326H5.71V8.073a2.282,2.282,0,0,0-.69-1.927L1.536,2.617A2.04,2.04,0,0,0,0,1.94V.866A.771.771,0,0,1,.872,0H3.457V2.9A1.2,1.2,0,0,0,4.785,4.232H7.5V9.46A.775.775,0,0,1,6.621,10.326Z"
                transform="translate(4.772 1.491)"
                fill="#ff2c53"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path-3"
                data-name="Path"
                d="M2.474,2.507H.313c-.221,0-.312-.1-.312-.312V0Z"
                transform="translate(9.407 2.038)"
                fill="#ff2c53"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path-4"
                data-name="Path"
                d="M6.621,10.326H.872A.773.773,0,0,1,0,9.453V.866A.771.771,0,0,1,.872,0H2.747V3.359A1.35,1.35,0,0,0,4.284,4.9H7.5V9.453A.777.777,0,0,1,6.621,10.326Z"
                transform="translate(1.491 4.915)"
                fill="#ff2c53"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path-5"
                data-name="Path"
                d="M3.333,3.385H.4c-.273,0-.4-.124-.4-.4V0Z"
                transform="translate(5.501 5.169)"
                fill="#ff2c53"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className={styles.selContainer}>
        <div
          className={styles.proxiesSelect}
          style={{ marginBottom: proxyCountry === "US" ? "10px" : "24px" }}
        >
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select onChange={handleSaucedTypeChange} className={styles.proxies}>
            {/* <option>Resi Proxy</option> */}
            <option value="lite">Lite</option>
            <option value="premium">Premium</option>
            <option value="saucedSheeesh">SaucedSheeesh</option>
          </select>
        </div>
        <div
          className={styles.proxiesSelect}
          style={{ marginBottom: proxyCountry === "US" ? "10px" : "24px" }}
        >
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select
            onChange={handleProxyTypeChange}
            className={styles.type}
            value={proxyType}
          >
            <option value="sticky">Sticky</option>
            <option value="rotating">Rotating</option>
          </select>
        </div>
        <div
          className={styles.proxiesSelect}
          style={{ marginBottom: proxyCountry === "US" ? "10px" : "24px" }}
        >
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select
            className={styles.type}
            onChange={handleCountryChange}
            value={proxyCountry}
          >
            <option>Select Country</option>
            {countryList}
          </select>
        </div>
        <div
          className={styles.proxiesSelect}
          style={{
            marginBottom: proxyCountry === "US" ? "10px" : "24px",
            display:
              proxyCountry === "US" && saucedType === "premium"
                ? "block"
                : "none",
          }}
        >
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select
            className={styles.type}
            onChange={handleUS_StateChange}
            // onChange={(e) => setProxyState(e.target.value)}
            value={US_State}
          >
            <option>Select State</option>
            {statesList}
          </select>
        </div>
        <input
          value={proxyQuantity > 0 && proxyQuantity}
          onChange={handleProxyQuantityChange}
          type="number"
          className={styles.quantity}
          placeholder="Quantity"
        />
      </div>
      <ul className={styles.proxiesList}>
        {resedintialProxies?.map((proxy, i) => (
          <li key={i}>{proxy}</li>
        ))}
      </ul>
    </div>
  );
};
export default ResiProxiesCard;
