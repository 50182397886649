import { Line } from "react-chartjs-2";
import { useState } from "react";
import styles from "./LineChart.module.css";

const LineChart = () => {
  const [labels, setLabels] = useState([
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]);
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 320);
    gradient.addColorStop(0, "#FF2C53");
    gradient.addColorStop(0.7, "rgba(255, 44, 83, 0.29)");
    return {
      labels,
      // labels,
      datasets: [
        {
          label: "",
          data: [0.1, 0.8, 0.6, 1.5, 2, 2.5, 1.5, 1],
          borderColor: "#FF2C53",
          backgroundColor: gradient,
          yAxisID: "yAxis",
          fill: true,
        },
      ],
    };
  };

  const options = {
    elements: { point: { radius: 0 } },
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        suggestedMin: 0,
        suggestedMax: 3,
        grid: { color: "rgb(21,80,88)", drawBorder: false },
        ticks: {
          callback: function (value, index, values) {
            return value + " GB  ";
          },
          padding: 15,
          color: "#5C898E",
          font: {
            size: 14,
            family: "TT commons",
            weight: "600",
          },
        },
      },
      xAxis: {
        grid: { display: false },
        ticks: {
          padding: 15,
          color: "#5C898E",
          font: {
            size: 14,
            family: "TT commons",
            weight: "600",
          },
          // callback: function (value, index, values) {
          //   return  value;
          // },
          autoSkip: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={styles.LineChartContainer}>
      <Line data={data} width={520} height={261} options={options} />
    </div>
  );
};
export default LineChart;
