import DoughnutCard from '../../components/DoughnutCard/DoughnutCard'
import GenerateCard from '../../components/GenerateCard/GenerateCard'
import Header from '../../components/Header/Header'
import LineChartCard from '../../components/LineChartCard/LineChartCard'
import ResiProxiesCard from '../../components/Proxies/ResiProxiesCard/ResiProxiesCard'
import styles from './Resi.module.css'

const Resi = ()=>{
    return <div className={styles.resiContainer}>
        <div className={styles.content}>
        <div className={styles.firstCol}>
            <LineChartCard/>
            <DoughnutCard title='Resi Proxy' />
        </div>
        <div className={styles.secondCol}>
            <ResiProxiesCard/>
            <GenerateCard/>
        </div>
        </div>
    </div>
}
export default Resi