import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  countries: [],
};
const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    getCountries(state, action) {
      state.countries = action.payload;
    },
  },
});
export default countriesSlice.reducer;
export const countriesActions = countriesSlice.actions;