import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./ResiDoughnutChart.module.css";
import { Doughnut } from "react-chartjs-2";

import axios from "axios";
const ResiDoughnutChart = (props) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);
  const saucedType = useSelector((state) => state.residential.saucedType);

  const [traffic, setTraffic] = useState(undefined);
  const [remainingQuota, setRemainingQuota] = useState(undefined);
  useEffect(() => {
    if (user) {
      axiosInstance
        .post("secretplan/remaining", {
          saucedType: saucedType,
        })
        .then((res) => {
          setRemainingQuota(res.data.remainingQuota);
          setTraffic(res.data.traffic);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setRemainingQuota(0);
      setTraffic(0);
    }
  }, [user, saucedType]);

  return (
    <div className={styles.doughnutChart}>
      {!user && (
        <div className={styles.notAvailable}>
          <h4>Please Login!</h4>
        </div>
      )}
      <Doughnut
        width={130}
        height={130}
        data={{
          labels: ["", "Remaining"],
          datasets: [
            {
              backgroundColor: ["rgba(224, 224, 255, 0.03)", "#FF2C53"],
              data: [traffic, remainingQuota],
              borderWidth: [0, 0],
              yAxisID: "yAxis",
            },
          ],
        }}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          cutout: 55,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
      <div className={styles.title}>
        <p className={styles.number}>{remainingQuota}</p>
        <p className={styles.gb}>GB remaining</p>
      </div>
    </div>
  );
};
export default ResiDoughnutChart;
