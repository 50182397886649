import Layout from "./components/Layout/Layout";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./App.css";
import Resi from "./pages/Resi/Resi";
import { Route, Switch } from "react-router";
import ISP from "./pages/ISP/ISP";
import Terms from "./pages/Terms/Terms";
import Cookies from "js-cookie";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { offsetsActions } from "./store/offsets";

import Modal from "./components/UI/Modal/Modal";
import Login from "./components/Login/Login";
import SideDrawer from "./components/Navigation/SideDrawer/SideDrawer";
import { residentialActions } from "./store/residential";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.offset.user);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);
  function closeModalLogin() {
    setShowLogin(false);
    setSideDrawer(false);
    setBackdrop(false);
  }

  const handleTabClosing = () => {
    dispatch(residentialActions.removeResedintialProxies([]));
    localStorage.removeItem("resedintial-lite-proxies");
    localStorage.removeItem("resedintial-premium-proxies");
  };
  useEffect(() => {
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  useEffect(() => {
    if (!user) {
      setShowLogin(true);
    }
  }, [user]);

  const closeModal = () => {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  };
  function closeBackdrop() {
    setBackdrop(false);
    setSideDrawer(false);
  }
  function showLoginFn() {
    setShowLogin(true);
    setSideDrawer(false);
  }
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    checkAfterDiscordAuth();
  }, []);
  function checkAfterDiscordAuth() {
    if (Cookies.get("subUser")) {
      axiosInstance
        .get("auth/login")
        .then((response) => {
          if (response.status === 200) {
            dispatch(offsetsActions.login(response.data.user));
            localStorage.setItem("auth-user-sauce", response.data.user);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  return (
    <>
      {!user &&
        ReactDOM.createPortal(
          <Modal show={showLogin} messageModal={true}>
            <Login hideClose={true} />
          </Modal>,
          document.getElementById("modal-root")
        )}
      {!user &&
        ReactDOM.createPortal(
          <SideDrawer
            show={sideDrawer}
            closeClicked={closeBackdrop}
            showLogin={showLoginFn}
          />,
          document.getElementById("sideDrawer-root")
        )}
      <Switch>
        <Layout>
          <Route path="/terms&conditions" component={Terms} />
          <Route path="/contact-us" component={Terms} />
          <Route path="/DC" component={ISP} />
          <Route path="/" exact component={Resi} />
        </Layout>
      </Switch>
    </>
  );
};

export default App;
