import styles from "./SideToggle.module.css";

const SideToggle = (props) => {
  return (
    <div
      className={styles.sideToggle}
      onClick={() => {
        props.clicked();
      }}
    >
      <div
        style={
          props.show
            ? { transform: "rotate(45deg)" }
            : { transform: "rotate(0)" }
        }
      ></div>
      <div
        style={
          props.show
            ? { transform: "translateX(20px)", opacity: "0" }
            : { transform: "translateX(0)", opacity: "1" }
        }
      ></div>
      <div
        style={
          props.show
            ? { transform: "rotate(-45deg)" }
            : { transform: "rotate(0)" }
        }
      ></div>
    </div>
  );
};
export default SideToggle;
