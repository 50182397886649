import React from "react";
import styles from "./Backdrop.module.css";

const Backdrop = (props) => {
  return (
    props.show && (
      <div
        className={props.modal ? styles.modalBackdrop : styles.backdrop}
        onClick={props.clicked}
      />
    )
  );
};

export default Backdrop;
