import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  planCapacity: 25,
  capacityPrice: 40,
  period: "monthly",
  priceId: "price_1JTS5vBQzqvKDx62vXokt6Zd",
  ISPPlans: [],
  activeOne: undefined,
  declinedMsgISP: false,
};
const dcISPPrices = {
  25: 40,
  50: 75,
  100: 140,
};
const dcISPPriceId = {
  25: "price_1JTS5vBQzqvKDx62vXokt6Zd",
  50: "price_1JTS6BBQzqvKDx623UoO0yJy",
  100: "price_1JTS6KBQzqvKDx62q1G8HUNN",
};

const dcIspSlice = createSlice({
  name: "ispPlan",
  initialState,
  reducers: {
    monthlyPrices(state, action) {
      state.capacityPrice = dcISPPrices[action.payload];
      state.planCapacity = action.payload;
      state.priceId = dcISPPriceId[action.payload];
    },
    changeISPPlans(state, action) {
      state.ISPPlans = action.payload;
    },
    changeActiveOne(state, action) {
      state.activeOne = action.payload;
    },
    changeDeclinedMsgISP(state, action) {
      state.declinedMsgISP = action.payload;
    },
  },
});
export default dcIspSlice.reducer;
export const dcIspActions = dcIspSlice.actions;
