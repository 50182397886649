import ReactDOM from "react-dom";
import Backdrop from "../UI/Backdrop/Backdrop";
import logosource from "../../assets/images/logosource.svg";
import styles from "./Navigation.module.css";
import NavigationItems from "./NavigationItems/NavigationItems";
import SideToggle from "./SideToggle/SideToggle";
import { NavLink } from "react-router-dom";

const Navigation = (props) => {
  return (
    <div className={styles.navigation}>
      <div className={styles.content}>
        <NavLink to="/">
          <img
            src={logosource}
            alt="logo"
            className={styles.logo}
            onClick={() => {
              props.closed();
              window.location.href = process.env.REACT_APP_WEBSITE;
            }}
          />
        </NavLink>
        <div className={styles.navItems}>
          <NavigationItems closed={props.closed} showLogin={props.showLogin} />
        </div>
        {ReactDOM.createPortal(
          <Backdrop show={props.backdrop} clicked={props.closeBackdrop} />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <SideToggle clicked={props.showSideDrawer} show={props.sideDrawer} />,
          document.getElementById("sideToggle-root")
        )}
      </div>
    </div>
  );
};
export default Navigation;
