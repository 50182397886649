import styles from "./Header.module.css";
import arrow from "../../assets/images/arrow.svg";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const Header = (props) => {
  const [proxy, setProxy] = useState("Resi Proxy");

  useEffect(() => {
    if (proxy === "Resi Proxy") {
      props.history.push(`/`);
    }
    if (proxy === "DC Proxy") {
      props.history.push(`/DC`);
    }
  }, [proxy, props.history]);

  return (
    <div className={styles.header}>
      <p className={styles.title}>Dashboard Proxies</p>
      <div className={styles.proxySelect}>
        <p className={styles.proxyPlan}>PROXY PLAN</p>
        <div className={styles.proxiesContainer}>
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select
            value={proxy}
            onChange={(e) => setProxy(e.target.value)}
            className={styles.proxies}
          >
            <option>Resi Proxy</option>
            <option>DC Proxy</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Header);
