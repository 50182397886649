import styles from "./LineChartCard.module.css";
import arrow from "../../assets/images/arrow.svg";
import LineChart from "./LineChart/LineChart";

const LineChartCard = () => {
  return (
    <div className={styles.lineChartContainer}>
      <div className={styles.notAvailable}>
        <h4>Coming Soon!</h4>
      </div>
      <div className={styles.header}>
        <p className={styles.usage}>Proxy Usage</p>
        <div className={styles.proxiesContainer}>
          <img src={arrow} alt="arrow" className={styles.arrow} />
          <select className={styles.proxies}>
            <option>Month</option>
          </select>
        </div>
      </div>
      <LineChart />
    </div>
  );
};
export default LineChartCard;
