import DoughnutCard from '../../components/DoughnutCard/DoughnutCard'
import GenerateCard from '../../components/GenerateCard/GenerateCard'
import OrderHistory from '../../components/OrderHistoryCard/OrderHistory'
import ISPProxiesCard from '../../components/Proxies/ISPProxiesCard/ISPProxiesCard'
import styles from './ISP.module.css'

const ISP = ()=>{
    return <div className={styles.ispContainer}>
                <div className={styles.content}>
        <div className={styles.firstCol}>
            <OrderHistory/>
            <DoughnutCard title='ISP Proxy' />
        </div>
        <div className={styles.secondCol}>
            <ISPProxiesCard/>
            <GenerateCard/>
        </div>
        </div>

    </div>
}
export default ISP