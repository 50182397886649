import styles from "./OrderHistory.module.css";
import { dcIspActions } from "../../store/dataCenter";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import Modal from "../UI/Modal/Modal";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const OrderHistory = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const user = useSelector((state) => state.offset.user);
  const ISPPlans = useSelector((state) => state.dc.ISPPlans);
  const activeOne = useSelector((state) => state.dc.activeOne);
  const [cancelled, setCancelled] = useState(false);
  const [show, setShow] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      let user_discord_id = Cookies.get("subUser")
        .slice(3, -1)
        .split(",")[0]
        .split(":")[1]
        .slice(1, -1);
      axiosInstance
        .post("ispsplan/get-user-plans", {
          user_discord_id: user_discord_id,
        })
        .then(({ data }) => {
          console.log(data, "response");
          dispatch(dcIspActions.changeISPPlans(data));
          dispatch(dcIspActions.changeActiveOne(data[0])); // first plan index zero is the active onde for the first time
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dispatch]);

  const changeActivePlan = (plan) => {
    dispatch(dcIspActions.changeActiveOne(plan)); // first plan index zero is the active onde for the first time
  };
  const handleBuyPlan = () => {
    window.location.href = process.env.REACT_APP_WEBSITE;
  };
  // cancel subscription
  const handleCancelSubscription = (subscriptionId) => {
    let user_discord_id = Cookies.get("subUser")
      .slice(3, -1)
      .split(",")[0]
      .split(":")[1]
      .slice(1, -1);
    // here the backend call to cancel subscription
    if (subscriptionId) {
      axiosInstance
        .post("cancel-subscription", {
          subscriptionId,
          user_discord_id,
        })
        .then((response) => {
          console.log(response.data, "cancel-subscription");
          console.log(response.data.status, "response.data.status");
          if (response.data.status) {
            setCancelled(true);
            setShow(true);
            setMessage("you cancelled your subscription");
            setMessageModal(true);
            // here I will fire a message ... you cancelled your subscription
          }
          // console.log(activeOne, "activeOne");
          let UpdatedISPPlans = [];
          ISPPlans.forEach((plan) => {
            if (plan.subscriptionId == subscriptionId) {
              plan = { ...plan, subscriptionId: "" };

              console.log(plan, "plan");
            }
            UpdatedISPPlans.push(plan);
          });
          console.log(UpdatedISPPlans, "UpdatedISPPlans");
          dispatch(dcIspActions.changeISPPlans(UpdatedISPPlans)); //activeplans
          dispatch(
            dcIspActions.changeActiveOne({
              //activeone
              ...activeOne,
              subscriptionId: "",
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const closeModal = () => {
    setShow(false);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
    setMessageModal(false);
  };
  return (
    <>
      {cancelled &&
        ReactDOM.createPortal(
          <Modal
            closeModal={closeModal}
            show={show}
            // stripe={loading}
            messageModal={messageModal}
          >
            <div className="message">
              <span>{message}</span>
              <button onClick={closeModal}>Ok</button>
            </div>{" "}
          </Modal>,
          document.getElementById("modal-root")
        )}
      <div className={styles.orderHistory}>
        {!user && (
          <div className={styles.notAvailable}>
            <h4>Please Login!</h4>
          </div>
        )}
        <div className={styles.header}>
          <p className={styles.usage}>Order History</p>
          <button onClick={handleBuyPlan} className={styles.buy}>
            BUY PLAN
          </button>
        </div>
        <div className={styles.ordersTable}>
          <table className={styles.orders}>
            <thead>
              <tr>
                <th>Plan</th>
                <th>Duration</th>
                <th>Data/Ip</th>
                <th>Renewal Date</th>
                <th>Cancellation</th>
              </tr>
            </thead>
            <tbody>
              {ISPPlans?.map((plan, index) => {
                let expirationDate = moment(plan.expiry);
                let todayDate = moment(new Date());
                let remainingDays = expirationDate.diff(todayDate, "days") + 1; //which mean the days left
                let traffic = 30 - remainingDays; //which mean the days that I used
                return (
                  <tr
                    key={index}
                    className={
                      activeOne?.username === plan.username
                        ? styles.activeplan
                        : ""
                    }
                    onClick={() => changeActivePlan(plan)}
                  >
                    {" "}
                    <th>{index + 1}</th>
                    <th>
                      {remainingDays} {remainingDays === 1 ? "Day" : "Days"}
                    </th>
                    <th>{plan.amount} proxies</th>
                    <th>{plan.expiry}</th>
                    <th>
                      {plan.subscriptionId && (
                        <button
                          onClick={() =>
                            handleCancelSubscription(plan.subscriptionId)
                          }
                          className={
                            activeOne?.username === plan.username
                              ? styles.activeplanbtn
                              : styles.cancel
                          }                        >
                          cancel
                        </button>
                      )}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default OrderHistory;
