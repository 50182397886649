import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Login from "../Login/Login";
import Navigation from "../Navigation/Navigation";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import Modal from "../UI/Modal/Modal";
import styles from "./Layout.module.css";
import MobileNotAllowed from "../UI/MobileNotAllowed/MobileNotAllowed";

const Layout = (props) => {
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);
  const [show, setShow] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const flagTrigger = useSelector((state) => state.offset.flag);

  const closeModal = () => {
    setShow(false);
    setSideDrawer(false);
    setBackdrop(false);
  };
  const showLogin = () => {
    setShow(true);
    setSideDrawer(false);
  };

  const showSideDrawer = () => {
    setBackdrop(!backdrop);
    setSideDrawer(!sideDrawer);
  };
  const closeBackdrop = () => {
    setBackdrop(false);
    setSideDrawer(false);
  };

  useEffect(() => {
    if (
      props.location.pathname === "/contact-us" ||
      props.location.pathname === "/terms&conditions"
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [props.location.pathname, flagTrigger]);

  return (
    <div className={styles.layout}>
      {ReactDOM.createPortal(
        <Modal show={show} closeModal={closeModal} messageModal={true}>
          <Login closeModal={closeModal} />
        </Modal>,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <MobileNotAllowed />,
        document.getElementById("notAllowed-root")
      )}

      <Navigation
        backdrop={backdrop}
        showSideDrawer={showSideDrawer}
        closeBackdrop={closeBackdrop}
        sideDrawer={sideDrawer}
        closed={closeBackdrop}
        showLogin={showLogin}
      />
      {ReactDOM.createPortal(
        <SideDrawer
          show={sideDrawer}
          closeClicked={closeBackdrop}
          showLogin={showLogin}
        />,
        document.getElementById("sideDrawer-root")
      )}
      {showHeader && <Header />}
      {props.children}
      <Footer />
    </div>
  );
};
export default Layout;
