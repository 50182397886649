import styles from "./NavigationItems.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { offsetsActions } from "../../../store/offsets";
import avatar from "../../../assets/images/avatar.png";
import bell from "../../../assets/images/bell.svg";
import { residentialActions } from "../../../store/residential";
import { countriesActions } from "../../../store/countriesSlice";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import axios from "axios";

const NavigationItems = (props) => {
  const dispatch = useDispatch();
  const [avatarImg, setAvatarImg] = useState("");

  const user = useSelector((state) => state.offset.user);

  const handleLogout = () => {
    dispatch(residentialActions.removeResedintialProxies([]));
    countriesActions.getCountries([]);
    dispatch(offsetsActions.logout(null));
    localStorage.removeItem("auth-user-sauce");
    localStorage.removeItem("resedintial-lite-proxies");
    localStorage.removeItem("resedintial-premium-proxies");
    Cookies.remove("subUser");
  };
  const changeFlag = () => dispatch(offsetsActions.setFlag());
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://cdn.discordapp.com/avatars/${user.user_discord_id}/${user.avatar}.gif`
        )
        .then((response) => {
          setAvatarImg(
            `https://cdn.discordapp.com/avatars/${user.user_discord_id}/${user.avatar}.gif`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (user) {
      axios
        .get(
          `https://cdn.discordapp.com/avatars/${user.user_discord_id}/${user.avatar}.webp`
        )
        .then((response) => {
          setAvatarImg(
            `https://cdn.discordapp.com/avatars/${user.user_discord_id}/${user.avatar}.webp`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);
  return (
    <nav className={styles.navigationItems}>
      <ul>
        <a href="https://saucedproxies.com/">
          <li
            onClick={() => {
              changeFlag();
              props.closed();
            }}
          >
            Home
          </li>
        </a>
        <NavLink to="/contact-us">
          <li
            onClick={() => {
              props.closed();
              changeFlag();
            }}
          >
            Contact Us
          </li>
        </NavLink>
        {!user && (
          <li>
            <button
              className={styles.navButtonRes}
              onClick={() => props.showLogin()}
            >
              Log In
            </button>
          </li>
        )}
        {user && (
          <li>
            <button className={styles.navButtonRes} onClick={handleLogout}>
              Log Out
            </button>
          </li>
        )}
      </ul>
      {user && (
        <div className={styles.loggedIn}>
          <img
            src={user.avatar && avatarImg ? avatarImg : avatar}
            alt="profilePic"
            className={styles.avatar}
          />
          <div className={styles.logoutContainer}>
            <p className={styles.name}>{user?.username}</p>
            <p className={styles.logout} onClick={handleLogout}>
              Log Out
            </p>
          </div>
        </div>
      )}

      {!user && (
        <button className={styles.navButton} onClick={() => props.showLogin()}>
          Log In
        </button>
      )}
    </nav>
  );
};
export default NavigationItems;
