import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import Backdrop from "../Backdrop/Backdrop";

const Modal = (props) => (
  <Fragment>
    {ReactDOM.createPortal(
      <Backdrop
        show={props.show}
        clicked={props.closeModal}
        modal={props.show}
      />,
      document.getElementById("backdrop-root")
    )}
    <div
      className={
        props.purchaseModal
          ? styles.purchaseModal
          : props.messageModal
          ? styles.messageModal
          : styles.Modal
      }
      style={{
        transform: props.show ? "translateX(0)" : "translateX(100vw)",
        opacity: props.show ? "1" : "0",
      }}
    >
      {props.children}
    </div>
  </Fragment>
);
export default Modal;
