import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./DoughnutCard.module.css";
import ISPDoughnutChart from "./ISPDoughnutChart/ISPDoughnutChart";
import ResiDoughnutChart from "./ResiDoughnutChart/ResiDoughnutChart";

const DoughnutCard = (props) => {
  const location = useLocation();
  const activeOne = useSelector((state) => state.dc.activeOne);

  const [expiration, setExpiration] = useState("");
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setExpiration("Lifetime");
        break;
      case "/DC":
        if (activeOne) setExpiration(activeOne?.expiry);
        break;
      default:
        break;
    }
  }, [location, activeOne]);
  return (
    <div className={styles.doughnutCard}>
      <div className={styles.textContainer}>
        <p className={styles.resi}>{props.title}</p>
        <p className={styles.souse}>Sauce Servers</p>
        <p className={styles.date}>{expiration}</p>
        <p className={styles.souse}>Expiration</p>
      </div>
      {props.title === "Resi Proxy" && (
        <ResiDoughnutChart
          title={props.title}
          chartNo={props.chartNo}
          chartText={props.chartText}
          color={props.color}
        />
      )}
      {props.title === "ISP Proxy" && (
        <ISPDoughnutChart
          title={props.title}
          chartNo={props.chartNo}
          chartText={props.chartText}
          color={props.color}
          activeOne={activeOne}
        />
      )}
    </div>
  );
};
export default DoughnutCard;
