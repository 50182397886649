import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usPlanCapacity: 25,
  usCapacityPrice: 70,
  priceId: "price_1JQbiHBQzqvKDx62v6bx56M7",
};
const usPlanPrices = {
  25: 70,
  50: 140,
  100: 280,
};
const usPriceId = {
  25: "price_1JQbiHBQzqvKDx62v6bx56M7",
  50: "price_1JQbiRBQzqvKDx62mkSQ3969",
  100: "price_1JQbijBQzqvKDx623vlCfplR",
};
const londonPlanPrices = {
  25: 72,
  50: 1999,
  100: 3699,
};
const ispSlice = createSlice({
  name: "ispPlan",
  initialState,
  reducers: {
    usPrices(state, action) {
      state.usCapacityPrice = usPlanPrices[action.payload];
      state.usPlanCapacity = action.payload;
      state.priceId = usPriceId[action.payload];
    },
    londonPrices(state, action) {
      state.londonCapacityPrice = londonPlanPrices[action.payload];
      state.londonPlanCapacity = action.payload;
    },
  },
});
export default ispSlice.reducer;
export const ispActions = ispSlice.actions;
